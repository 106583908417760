import axios from 'axios';
import eachElement from 'Utils/eachElement.js';
import errorHandler from 'Utils/errorHandler.js';

import defaultFields from './data/webinars/defaultFields.js';
import fieldConfiguration from './data/webinars/fieldConfiguration.js';

class WebinarFormField {
	constructor(config) {
		/* DOM elements */
		this.field = null;
		this.column = null;
		this.fieldContainer = null;

		/* Configuration */
		this.fieldName = config.fieldName;
		this.label = config.label;
		this.required = config.required || false;
		this.type = this.normalizeType(config.type);
		this.width = config.width || 6;
		this.options = config.options || [];

		/* Create the field */
		this.init();
	}

	/* Create the field */
	init() {
		/* Create field container */
		this.createContainer();

		/* Create field DOM */
		if (this.type === 'checkbox') {
			this.createCheckboxInput();
		} else {
			this.createSingleInput();
		}
	}

	/* Return field HTML */
	toHTML() {
		return this.column;
	}

	/* Create a single input field */
	createSingleInput() {
		/* Type specific setup */
		switch (this.type) {
			case 'select':
				this.field = document.createElement('select');
				this.addEmptySelectOption();
				this.addSelectOptions();
				break;

			case 'textarea':
				this.field = document.createElement('textarea');
				this.field.className = 'textarea';
				break;

			default:
				this.field = document.createElement('input');
				this.field.type = this.type;
				this.field.className = 'input';
		}

		/* Set input attributes */
		this.field.name = this.fieldName;
		this.field.required = this.required;
		this.field.placeholder = this.getPlaceholder();

		/* Append input to field container */
		this.fieldContainer.append(this.field);
	}

	/* Create a multi-checkbox input */
	createCheckboxInput() {
		/* Create a title */
		const title = document.createElement('h3');
		title.className = 'title is-5';
		title.textContent = this.label;
		this.fieldContainer.append(title);

		/* Create a checkbox container */
		const checkboxContainer = document.createElement('div');
		checkboxContainer.className = 'checkboxes';
		this.fieldContainer.append(checkboxContainer);

		/* Create a checkbox for each option */
		for (const option of this.options) {
			const checkbox = document.createElement('input');
			checkbox.type = 'checkbox';
			checkbox.name = this.fieldName;
			checkbox.value = option;
			checkbox.id = `${this.fieldName}-${option}`;

			const label = document.createElement('label');
			label.className = 'checkbox is-block py-1';
			label.htmlFor = checkbox.id;
			label.textContent = ` ${option}`;

			label.prepend(checkbox);
			checkboxContainer.append(label);
		}
	}

	/* Create appropriate column and field container(s) */
	createContainer() {
		/* Create column markup */
		this.column = document.createElement('div');
		this.column.className = `column is-${this.width}-tablet`;

		/* Create field container */
		this.fieldContainer = document.createElement('div');

		/* If it's a select, we need a second nested container */
		if (this.type === 'select') {
			this.fieldContainer.className = 'select is-fullwidth';

			const outerContainer = document.createElement('div');
			outerContainer.classList.add('field');
			outerContainer.append(this.fieldContainer);
			this.column.append(outerContainer);
		} else {
			this.fieldContainer.classList.add('field');
			this.column.append(this.fieldContainer);
		}
	}

	/* Construct placeholder string from field options */
	getPlaceholder() {
		return `${this.label}${this.required ? '*' : ''}`;
	}

	/* Make sure field type is lowecase, and Zoom special types are converted to HTML types */
	normalizeType(type) {
		const types = {
			short: 'text',
			singleradio: 'select',
			singledropdown: 'select',
			multiple: 'checkbox',
		};

		const lowercaseType = String(type).toLowerCase();

		return types[lowercaseType] || lowercaseType;
	}

	/* Add options, if appropriate */
	addSelectOptions() {
		if (this.options && this.options.length > 0) {
			for (const option of this.options) {
				const optionElement = document.createElement('option');

				if (Array.isArray(option)) {
					optionElement.value = option[0];
					optionElement.text = option[1];
				} else {
					optionElement.value = option;
					optionElement.text = option;
				}

				this.field.append(optionElement);
			}
		}
	}

	/* Add an empty option to a select element */
	addEmptySelectOption() {
		/* Add empty option */
		const emptyOption = document.createElement('option');
		emptyOption.value = '';
		emptyOption.text = this.getPlaceholder();
		emptyOption.selected = true;
		emptyOption.disabled = true;
		this.field.prepend(emptyOption);
	}
}

class WebinarForm {
	constructor(form) {
		if (!form) {
			errorHandler('No webinar registration form element found');
		}

		/* Form DOM element */
		this.form = form;

		/* API host */
		this.host = form.dataset.host || 'http://localhost:1235';

		/* Zoom ID of the event */
		this.webinarId = form.dataset.id || new URLSearchParams(window.location.search).get('wid');

		/* Type of Zoom event; one of 'webinar' or 'meeting' */
		this.webinarType = form.dataset.type || 'webinar';

		/* Columns container inside form */
		this.columns = form.querySelector('.columns');

		/* Fields to be displayed on the form */
		this.fields = [];

		/* Fields that every registration form has (and are thus not returned by the Zoom API) */
		this.defaultFields = defaultFields;

		/* Settings for known Zoom fields; order of this object controls order of display */
		this.fieldConfiguration = fieldConfiguration;

		/* List of countries accepted by Zoom */
		this.countries = [];

		/* If no webinar ID was found, go to the main webinars page */
		if (!this.webinarId) {
			this.goAway();
		}

		this.init();
	}

	init() {
		/* Empty existing form */
		this.columns.innerHTML = '';

		/* Get fields from Zoom */
		this.getFields().then(() => {
			this.createFields();
		});

		/* Listen for submission */
		this.form.addEventListener('submit', event => {
			this.submit(event);
		});
	}

	/* Return to main webinars page */
	goAway() {
		window.location.href = '/resources/webinars';
	}

	/* Get fields from Zoom API */
	async getFields() {
		try {
			const { data } = await axios.get(`${this.host}/v1/zoom/${this.webinarType}/${this.webinarId}/questions`);

			const builtInFields = [...this.defaultFields, ...data.questions];

			/* Default and requested built-in fields */
			/* Loop fieldConfiguration in order to preserve locally defined field order */
			for (const fieldName in this.fieldConfiguration) {
				if (Object.prototype.hasOwnProperty.call(this.fieldConfiguration, fieldName)) {
					/* Only add field if it's requested in the Zoom response */
					const fieldSettings = builtInFields.find(field => field.fieldName === fieldName);
					if (fieldSettings) {
						/* Combine config from local and Zoom */
						const field = { ...this.fieldConfiguration[fieldName], ...fieldSettings };

						/* Get country options if necessary */
						if (fieldName === 'Country') {
							if (this.countries.length === 0) {
								/* eslint-disable-next-line no-await-in-loop */
								await this.getCountryOptions();
							}

							field.options = this.countries;
						}

						/* Add field to list */
						this.fields.push(field);
					}
				}
			}

			/* Custom fields */
			for (const question of data.customQuestions) {
				this.fields.push({
					fieldName: `CustomQuestions[${question.title}]`,
					label: question.title,
					type: question.type,
					required: question.required,
					options: question.answers,
					width: 12,
				});
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	/* Create form fields */
	createFields() {
		for (const field of this.fields) {
			const fieldElement = new WebinarFormField(field);
			this.columns.append(fieldElement.toHTML());
		}
	}

	/* Get form values as JSON */
	getValuesAsJson() {
		/* Get the field values */
		const formData = new FormData(this.form);
		const formJson = {};

		/* Loop through all fields */
		/* Necessary to handle multiple values for checkboxes */
		for (const [key, value] of formData.entries()) {
			if (formJson[key]) {
				/* If the key already exists, add to CSV */
				formJson[key] = `${formJson[key]}, ${value}`;
			} else {
				formJson[key] = value;
			}
		}

		/* Add webinar ID */
		formJson.WebinarId = this.webinarId;

		/* Set default for State if not provided */
		if (!formJson.State) {
			formJson.State = 'na';
		}

		/* Loop through all keys in formJson */
		for (const key in formJson) {
			/* If the key begins with customQuestions, move it to a nested array */
			if (key.startsWith('CustomQuestions')) {
				const customQuestion = key.replace('CustomQuestions[', '').replace(']', '');
				formJson.CustomQuestions = formJson.CustomQuestions || [];
				formJson.CustomQuestions.push({ Title: customQuestion, Value: formJson[key] });
				delete formJson[key];
			}
		}

		return formJson;
	}

	/* Handle submitting the form */
	submit(event) {
		event.preventDefault();

		/* Disable submit button */
		this.form.querySelector('button').disabled = true;

		/* Get form as JSON */
		const formJson = this.getValuesAsJson();

		/* Send registration request */
		axios.post(`${this.host}/v1/zoom/${this.webinarType}/${this.webinarId}/register`, formJson)
			.then(() => {
				/* Show success message */
				const banner = this.form.querySelector('.notification.is-green');
				banner.innerHTML = 'Thank you for registering for this webinar. <br/> A confirmation email is on its way. See you there!';
				banner.classList.remove('is-hidden');

				/* Hide form */
				this.form.querySelector('article').classList.add('is-hidden');
			})
			.catch(error => {
				/* Show error message */
				const banner = this.form.querySelector('.notification.is-red');
				banner.textContent = 'Something went wrong. Please check the form and try again.';
				banner.classList.remove('is-hidden');

				/* Re-enable button */
				this.form.querySelector('button').disabled = false;

				/* Send to Raygun */
				errorHandler(error);
			});

		return false;
	}

	/* Populate a given select with options for countries, as recognised by Zoom */
	getCountryOptions() {
		return axios.get(`${this.host}/v1/zoom/countrycodes`)
			.then(response => {
				const sortedCountries = [];

				/* Convert object to array for sorting */
				for (const code in response.data) {
					if (Object.prototype.hasOwnProperty.call(response.data, code)) {
						sortedCountries.push([code, response.data[code]]);
					}
				}

				/* Sort countries alphabetically by label */
				sortedCountries.sort((a, b) => a[1].localeCompare(b[1]));

				this.countries = sortedCountries;
			}).catch(errorHandler);
	}
}


const setUpWebinarRegistration = () => {
	eachElement('form.webinar-registration', form => {
		new WebinarForm(form);
	});
};

export default {
	setUpWebinarRegistration,
};
