export default {
	FirstName: {
		type: 'text',
		label: 'First name',
	},
	LastName: {
		type: 'text',
		label: 'Last name',
	},
	Email: {
		type: 'email',
		label: 'Work email',
	},
	Phone: {
		type: 'tel',
		label: 'Phone number',
	},
	Address: {
		type: 'text',
		label: 'Address',
		width: 8,
	},
	City: {
		type: 'text',
		label: 'City',
		width: 4,
	},
	State: {
		type: 'select',
		label: 'State',
		width: 4,
		options: [
			['AL', 'Alabama'],
			['AK', 'Alaska'],
			['AZ', 'Arizona'],
			['AR', 'Arkansas'],
			['CA', 'California'],
			['CO', 'Colorado'],
			['CT', 'Connecticut'],
			['DE', 'Delaware'],
			['FL', 'Florida'],
			['GA', 'Georgia'],
			['HI', 'Hawaii'],
			['ID', 'Idaho'],
			['IL', 'Illinois'],
			['IN', 'Indiana'],
			['IA', 'Iowa'],
			['KS', 'Kansas'],
			['KY', 'Kentucky'],
			['LA', 'Louisiana'],
			['ME', 'Maine'],
			['MD', 'Maryland'],
			['MA', 'Massachusetts'],
			['MI', 'Michigan'],
			['MN', 'Minnesota'],
			['MS', 'Mississippi'],
			['MO', 'Missouri'],
			['MT', 'Montana'],
			['NE', 'Nebraska'],
			['NV', 'Nevada'],
			['NH', 'New Hampshire'],
			['NJ', 'New Jersey'],
			['NM', 'New Mexico'],
			['NY', 'New York'],
			['NC', 'North Carolina'],
			['ND', 'North Dakota'],
			['OH', 'Ohio'],
			['OK', 'Oklahoma'],
			['OR', 'Oregon'],
			['PA', 'Pennsylvania'],
			['RI', 'Rhode Island'],
			['SC', 'South Carolina'],
			['SD', 'South Dakota'],
			['TN', 'Tennessee'],
			['TX', 'Texas'],
			['UT', 'Utah'],
			['VT', 'Vermont'],
			['VA', 'Virginia'],
			['WA', 'Washington'],
			['WV', 'Wet Virginia'],
			['WI', 'Wisconsin'],
			['WY', 'Wyoming'],
			['AB', 'Alberta'],
			['BC', 'British Columbia'],
			['MB', 'Manitoba'],
			['NB', 'New Brunswick'],
			['NL', 'Newfoundland'],
			['NS', 'Nova Scotia'],
			['NU', 'Nunavut'],
			['ON', 'Ontario'],
			['PE', 'Prince Edward Island'],
			['QC', 'Quebec'],
			['SK', 'Saskatchewan'],
			['NT', 'Northwest Territories'],
			['YT', 'Yukon Territory'],
			['AA', 'Armed Forces Americas'],
			['AE', 'Armed Forces Europe, Middle East, and Canada'],
		],
	},
	Zip: {
		type: 'text',
		label: 'ZIP code',
		width: 3,
	},
	Country: {
		type: 'select',
		label: 'Country',
		width: 5,
	},
	Org: {
		type: 'text',
		label: 'Company',
		width: 4,
	},
	Industry: {
		type: 'select',
		label: 'Industry',
		width: 4,
		options: [
			'Accounting',
			'Advertising/Marketing/PR',
			'Aerospace & Defense',
			'Agriculture',
			'Banking & Securities',
			'Call Center Outsourcing',
			'Consulting',
			'Consumer Products',
			'Education',
			'Energy, Chemical, Utilities',
			'Financial Services - Other',
			'Government - Federal',
			'Government - State & Local',
			'High Tech - Hardware',
			'High Tech - ISP',
			'High Tech - Other',
			'Hospital, Clinic, Doctor Office',
			'Hospitality, Travel, Tourism',
			'Insurance',
			'Legal',
			'Manufacturing',
			'Medical, Pharma, Biotech',
			'Real Estate',
			'Retail',
			'Software - Finance',
			'Software - Healthcare',
			'Software - Other',
			'Support Outsourcing',
			'Telecommunications',
			'Transportation & Distribution',
			'VAR/Systems Integrator',
			'Other',
		],
	},
	NoOfEmployees: {
		type: 'select',
		label: 'Number of employees',
		options: [
			'1-20',
			'21-50',
			'51-100',
			'101-500',
			'500-1,000',
			'1,001-5,000',
			'5,001-10,000',
			'More than 10,000',
		],
		width: 4,
	},
	JobTitle: {
		type: 'text',
		label: 'Job title',
		width: 4,
	},
	PurchasingTimeFrame: {
		type: 'select',
		label: 'Purchasing time frame',
		options: [
			'Within a month',
			'1-3 months',
			'4-6 months',
			'More than 6 months',
			'No timeframe',
		],
		width: 4,
	},
	RoleInPurchaseProcess: {
		type: 'select',
		label: 'Role in purchase process',
		options: [
			'Decision Maker',
			'Evaluator/Recommender',
			'Influencer',
			'Not involved',
		],
		width: 4,
	},
	Comments: {
		type: 'textarea',
		label: 'Comments',
		width: 12,
	},
};
