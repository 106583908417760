export default [
	{
		fieldName: 'FirstName',
		required: true,
	},
	{
		fieldName: 'Email',
		required: true,
	},
];
